import React, { useEffect } from "react";
import AboutImg3 from "../images/about-img3.jpg";
import AboutImg4 from "../images/about-img4.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const About2 = () => {
  useEffect(() => {
    Aos.init({});
  }, []);

  useEffect(() => {
    const elts = {
      text1: document.getElementById("text1"),
      text2: document.getElementById("text2"),
    };

    const texts = ["Contact me", "If you'd like to", "Discuss a project"];

    const morphTime = 1.3;
    const cooldownTime = 1;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    function setMorph(fraction) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 150)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 150)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = "";
      elts.text2.style.opacity = "100%";

      elts.text1.style.filter = "";
      elts.text1.style.opacity = "0%";
    }

    function animate() {
      requestAnimationFrame(animate);

      let newTime = new Date();
      let shouldIncrementIndex = cooldown > 0;
      let dt = (newTime - time) / 1000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    animate();
  }, []);

  return (
    <section class="about" id="about">
      <h1
        class="ml11 title aboutTitle"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
      >
        <span class="text-wrapper">
          <span class="line line1"></span>
          <span class="letters">About</span>
        </span>
      </h1>

      <div className="aboutWrap">
        <figure
          class="snip1104 red red1"
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
        >
          <img src={AboutImg3} />
          <figcaption>
            <h2>Bio</h2>
          </figcaption>

          <span className="aboutSpan">
            Hi there! My name is Oleg and I specialize in developing responsive
            websites with the latest technologies. <br></br>
            <br></br>I enjoy following the latest design trends and coming up
            with my own solutions and custom effects.
            <br></br>
            <br></br>In addition to Javascript, I am familiar with e-commerce
            and game development with C# and Unity.
          </span>
        </figure>

        <figure
          class="snip1104 red red2"
          data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000"
        >
          <img src={AboutImg4} />
          <figcaption>
            <h2>Contact</h2>
          </figcaption>
          <span className="aboutSpan">
            customdesigns7777@gmail.com <br></br>
            <br></br>Tallinn, Estonia
          </span>
        </figure>
      </div>

      <div
        id="aboutContainer"
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-easing="ease-in-out"
        data-aos-delay="100"
      >
        <span id="text1"></span>
        <span id="text2"></span>
      </div>

      <div
        data-aos="fade-down"
        data-aos-duration="600"
        data-aos-easing="ease-in-out"
        data-aos-delay="250"
      >
        <a
          className=""
          href="mailto:customdesigns7777@gmail.com"
          class="btn-flip"
          data-back="Compose"
          data-front="Email"
        ></a>
      </div>
    </section>
  );
};

export default About2;

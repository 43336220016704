import React from "react";
import styled from "styled-components";

import Aos from "aos";
import { useEffect } from "react";

const Dropdown = ({ isOpen, toggle }) => {
  const scrollBackToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    Aos.init({});
  }, []);

  function scrollToProjects() {
    let scrollToElement = document.querySelector(".PortfolioPage");
    scrollToElement.scrollIntoView();
  }

  function scrollToAbout() {
    let scrollToElement = document.querySelector(".about");
    scrollToElement.scrollIntoView();
  }

  function scrollToSkills() {
    let scrollToElement = document.querySelector(".skillsContainer");
    scrollToElement.scrollIntoView();
  }

  function removeActive() {
    if (document.querySelector(".toggler").checked) {
      document.querySelector(".toggler").checked = false;
    }
  }

  return (
    <DropdownContainer
      isOpen={isOpen}
      onClick={function () {
        toggle();
        removeActive();
      }}
    >
      <Icon onClick={toggle}></Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <div onClick={scrollBackToTop} className="dropDownLink">
            Home
          </div>
          <div onClick={scrollToSkills} className="dropDownLink">
            Skills
          </div>
          <div onClick={scrollToProjects} className="dropDownLink">
            Work
          </div>
          <div onClick={scrollToAbout} className="dropDownLink">
            About
          </div>
        </DropdownMenu>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;

const DropdownContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: black;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const DropdownWrapper = styled.div`
  color: #fff;
`;
const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 80px);
  text-align: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(4, 60px);
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";

const Navbar = styled.nav`
  height: 60px;
  padding: 0rem calc((100vw - 1300px) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const Logo = styled(Link)`
  color: #fff;
  padding-left: 1rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
`;

const NavItems = styled.div``;

const Header = ({ toggle }) => {
  const scrollBackToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.querySelector(".navBar").classList.add("pop_out");
        document.querySelector(".navBar").classList.remove("pop_in");

        document.querySelector(".logo").classList.remove("navColorUp");
        document.querySelector(".logo").classList.add("navColorDown");
      } else if (window.top) {
        document.querySelector(".navBar").classList.remove("pop_out");
        document.querySelector(".navBar").classList.add("pop_in");

        document.querySelector(".logo").classList.add("navColorUp");
        document.querySelector(".logo").classList.remove("navColorDown");
      }
    }
  }, []);

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  function changeLogoBack() {
    let logoEle = document.querySelector(".logo");
    logoEle.classList.add("logoLeave");
  }

  return (
    <Navbar className="navBar">
      <Logo
        onClick={scrollBackToTop}
        className="logo"
        onMouseLeave={changeLogoBack}
      >
        <span>O</span>
        <span>l</span>
        <span>e</span>
        <span>g</span>
        <span>K</span>
      </Logo>
      <NavItems scrollNav={scrollNav}>
        <Link
          className="hvr-underline-reveal hvr-overline-from-right link link--yaku #section_2"
          spy={true}
          exact="true"
          offset={-50}
          to="skillsContainer"
        >
          <span>S</span>
          <span>k</span>
          <span>i</span>
          <span>l</span>
          <span>l</span>
          <span>s</span>
        </Link>

        <Link
          className="hvr-underline-reveal hvr-overline-from-right link link--yaku #section_3"
          spy={true}
          exact="true"
          offset={-35}
          to="main-content"
        >
          <span>W</span>
          <span>o</span>
          <span>r</span>
          <span>k</span>
        </Link>
        <Link
          className="hvr-underline-reveal hvr-overline-from-right link link--yaku"
          to="about"
          spy={true}
          exact="true"
          offset={-30}
        >
          <span>A</span>
          <span>b</span>
          <span>o</span>
          <span>u</span>
          <span>t</span>
        </Link>

        <div id="menuToggle">
          <input type="checkbox" className="toggler" onClick={toggle} />

          <span id="span1"></span>
          <span id="span2"></span>
          <span id="span3"></span>
        </div>
      </NavItems>
    </Navbar>
  );
};

export default Header;

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaHome } from "react-icons/fa";

function MenuItems({ menuItem }) {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <div className="portfolis">
      {menuItem.map((item) => {
        return (
          <div
            className="portfolio"
            key={item.id}
            data-aos={item.aosData}
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            <div className="image-data">
              <p
                className="projectsP"
                data-aos="fade-right"
                data-aos-delay="250"
                data-aos-easing="ease-in-sine"
              >
                {item.title}
              </p>

              <img className="portImage" src={item.image} alt="" />
              <ul className="hover-items">
                <li className="portLi">
                  <a className="portA" target="_blank" href={item.link1}>
                    <FaHome size={30} />
                  </a>
                </li>
              </ul>

              <ul className="hover-items2">
                <li className="portLi">
                  <span className="projectDesc">{item.description}</span>
                </li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MenuItems;

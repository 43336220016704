import React, { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import Typewriter from "typewriter-effect";
import HeroSlider, { Slide, SideNav, OverlayContainer } from "hero-slider";

import Wrapper from "./Wrapper";
import image2 from "../images/hero-img3.jpg";
import image10 from "../images/hero-img10.jpg";
import image9 from "../images/hero-img9.jpg";

const Section = styled.section`
  background: url(${({ image }) => image && image}) center;
  height: 100vh;
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
`;

const Container = styled.div`
  color: #fff;
  padding: 1.6rem;
  width: 40rem;
  /* backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4); */
  /* margin-left: 6rem; */
  border-radius: 50px;

  @media (max-width: 840px) {
    margin-left: 3rem;
    width: 34rem;
  }

  @media (max-width: 670px) {
    margin-left: 2rem;
    width: 30rem;
  }

  @media (max-width: 550px) {
    margin-left: 1rem;
  }

  @media (max-width: 530px) {
    margin-left: 0.5rem;
    width: 27rem;
  }

  @media (max-width: 500px) {
    margin-left: 0.4rem;
    width: 23rem;
  }

  @media (max-width: 400px) {
    margin-left: 0.3rem;
    width: 22rem;
  }

  @media (max-width: 380px) {
    margin-left: 0.3rem;
    width: 20rem;
  }

  @media (max-width: 350px) {
    margin-left: 0.3rem;
    width: 19rem;
  }

  @media (max-width: 330px) {
    margin-left: 0.1rem;
    width: 18.7rem;
  }

  @media (max-width: 280px) {
    width: 17rem;
  }
`;

const Hero = ({ image, title, desc }) => {
  function scrollToProjects() {
    let scrollToElement = document.querySelector(".skillsContainer");
    scrollToElement.scrollIntoView();
  }

  useEffect(() => {
    Aos.init({ disable: "mobile" });
  }, []);

  useEffect(() => {
    const spans = document.querySelectorAll(".word span");

    spans.forEach((span, idx) => {
      span.addEventListener("click", (e) => {
        e.target.classList.add("active");
      });
      span.addEventListener("animationend", (e) => {
        e.target.classList.remove("active");
      });

      setTimeout(() => {
        span.classList.add("active");
      }, 750 * (idx + 1));
    });
  }, []);

  return (
    <HeroSlider
      slidingAnimation="fade"
      orientation="horizontal"
      initialSlide={1}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)",
      }}
      settings={{
        slidingDuration: 350,
        slidingDelay: 3,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 3000,
        height: "100vh",
        shouldSlideOnArrowKeypress: true,
      }}
    >
      <Section>
        <OverlayContainer>
          <Wrapper>
            <Container>
              <section className="home" id="home">
                <div className="max-width">
                  <div className="home-content">
                    <div
                      className="text-2"
                      data-aos="fade-in"
                      data-aos-delay="800"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="500"
                    >
                      <span>Services</span>
                    </div>

                    <div
                      class="text-3"
                      data-aos="fade-in"
                      data-aos-delay="800"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="500"
                    >
                      <span class="typing">
                        {
                          <Typewriter
                            options={{
                              strings: [
                                "Web development",
                                "UI/UX design",
                                "E-commerce",
                              ],
                              autoStart: true,
                              loop: true,
                              delay: 125,
                              pauseFor: 4000,
                            }}
                          />
                        }
                      </span>
                    </div>

                    <div
                      className="arrowContainer"
                      onClick={scrollToProjects}
                      data-aos="fade-in"
                      data-aos-delay="800"
                      data-aos-offset="300"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="500"
                    >
                      <div className="chevron"></div>
                      <div className="chevron"></div>
                      <div className="chevron"></div>
                    </div>
                  </div>
                </div>
              </section>
            </Container>
          </Wrapper>
        </OverlayContainer>
      </Section>
      <Slide
        background={{
          backgroundImage: image10,
          backgroundAttachment: "fixed",
        }}
      />

      <Slide
        background={{
          backgroundImage: image9,
          backgroundAttachment: "fixed",
        }}
      />

      <Slide
        background={{
          backgroundImage: image2,
          backgroundAttachment: "fixed",
        }}
      />

      <SideNav color="#fff" activeColor="#04d9ff" />
    </HeroSlider>
  );
};

export default Hero;

import React, { useEffect } from "react";
import Categories from "./Categories";
import MenuItems from "./MenuItems";
import portfolios from "./allportfolios";
import { useState } from "react";

const allCategories = [
  "All",
  ...new Set(portfolios.map((item) => item.category)),
];

function PortfoliosPage() {

  useEffect(() => {
    let activeBtn = document.querySelector(".btn-port");
    activeBtn.classList.add("activeBtn");
  }, []);

  const [categories, setCategories] = useState(allCategories);
  const [menuItems, setMenuItems] = useState(portfolios);

  const filter = (category) => {
    let activeBtn = document.querySelector(".btn-port");
    activeBtn.classList.remove("activeBtn");
    if (category === "All") {
      setMenuItems(portfolios);
      return;
    }
    const filteredData = portfolios.filter((item) => {
      return item.category === category;
    });
    setMenuItems(filteredData);
  };

  return (
    <div className="main-content">
      <div className="content">
        <div className="PortfolioPage">
          <h2
            class="ml3 title projectsTitle"
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            Work
          </h2>
          <div className="portfolios-data">
            <Categories filter={filter} categories={categories} />
            <MenuItems menuItem={menuItems} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfoliosPage;

import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Skills = () => {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <div className="skillsContainer">
      <h2
        className="ml14 title skillsTitle"
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="1000"
      >
        <span className="text-wrapper skillLetters">
          <span className="letters skillLetters">Skills</span>
          <span className="line"></span>
        </span>
      </h2>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="container-general">
              <div className="gallery-wrap wrap-effect-1">
                <div
                  className="grid item firstSkills"
                  data-aos="zoom-out"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <figure className="effect-roxy item firstSkills">
                    <figcaption className="skillsParagraph">

                      <p className="skillsP">
                        I enjoy working with CSS and designing smooth and responsive websites with unique looks and effects. I have experience with SCSS and Styled Components as well.
                      </p>
                    </figcaption>
                  </figure>
                </div>

                <div
                  className="grid item"

                  data-aos="zoom-out"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <figure className="effect-roxy item item3">
                    <figcaption>
                      <p>
                        As a hobby, I learned C# to understand how game development works. I have experience in creating games with the Unity engine which runs on C#. 
                      </p>
                    </figcaption>
                  </figure>
                </div>

                <div
                  className="grid item"
                  data-aos="zoom-out"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <figure className="effect-roxy item item2">
                    <figcaption>
                      <p>
                        I have extensive experience with Javascript and with React. Developing apps, adding functionality to websites and combining it with beautiful CSS is my passion.
                      </p>
                    </figcaption>
                  </figure>
                </div>

                <div
                  className="grid item"
                  data-aos="zoom-out"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <figure className="effect-roxy item item4">
                    <figcaption>
                      <p>
                        I have used SQL on a daily basis to look up information, to manipulate it and to force changes, such as initiating an inventory sync for Amazon.
                      </p>
                    </figcaption>
                  </figure>
                </div>

                <div
                  className="grid item lastGrid"
                  data-aos="zoom-out"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <figure className="effect-roxy item lastSkills">
                    <figcaption>
                      <p>
                        Having worked in the e-commerce business for several years, I can provide solutions on how to automate a business to increase efficiency and to lower costs.
                      </p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;

import React from 'react';

const Footer = () => {
  return (
    <footer class='footer'>
      <p>
        &copy; <span id='date'></span> All rights reserved
      </p>
    </footer>
  );
};

export default Footer;

import port1 from "../images/aboutUs1.png";
import port2 from "../images/aboutUs2.png";
import port3 from "../images/aboutUs3.png";
import port4 from "../images/aboutUs4.png";

const portfolios = [
  {
    id: 1,
    category: "React",
    link1: "https://store-2b2c9.web.app",
    icon1: "G",
    icon2: "Y",
    image: port1,
    aosData: "fade-right",
    title: "Online Store",
    description:
      "A responsive online store with a unique design and made with React.",
  },
  {
    id: 2,
    category: "Gatsby",
    link1: "https://smooth3-9862a.web.app/",
    icon1: "G",
    icon2: "Y",
    image: port2,
    title: "Landing Page",
    aosData: "fade-right",
    description: "A modern looking business landing page made with Gatsby.",
  },
  {
    id: 3,
    category: "JS",
    link1: "https://dreamy-poincare-d40cb5.netlify.app/",
    icon1: "G",
    icon2: "Y",
    image: port3,
    title: "Meditation App",
    aosData: "fade-right",
    description:
      "An app that helps to relax by visualizing breathing in and out.",
  },
  {
    id: 4,
    category: "JS",
    link1: "https://agitated-allen-7bd8ed.netlify.app/",
    icon1: "G",
    icon2: "Y",
    image: port4,
    title: "JS Game",
    aosData: "fade-right",
    description: "My very first coding project, a game made with vanilla JS.",
  },
];

export default portfolios;

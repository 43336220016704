import React from "react";

function Categories({ filter, categories }) {
  return (
    <div className="buttons"
    data-aos="fade-right"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="1000"
    >
      {categories.map((cat, i) => {
        return (
          <button
            type="button"
            className="btn-port"
            onClick={() => filter(cat)}
            key={i}
          >
            {cat}
          </button>
        );
      })}
    </div>
  );
}

export default Categories;

import React, { useState } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Image from "../images/img-5.jpg";
import "aos/dist/aos.css";
import Skills from "../components/Skills";
import About2 from "../components/About2";
import Dropdown from "../components/Dropdown";
import Footer from "../components/Footer";
import PortfoliosPage from "../components/SortedProjects";
import ScrollButton from "../components/ScrollButton";
import MouseParticles from "react-mouse-particles";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setHeight(window.pageYOffset);
    });
    return () => window.removeEventListener("scroll", () => {});
  });

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <Hero image={Image} />
      <Skills />
      <PortfoliosPage />
      <About2 />
      <Footer />
      <ScrollButton height={height} />
      <MouseParticles g={1} color="#04d9ff" cull="col,image-wrapper" />
    </div>
  );
};

export default Home;
